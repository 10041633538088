<template>

    <div class="bg-white pb-4">
        <div class="container py-4">
               <!-- Carousel -->
<div id="artworkslides" class="carousel slide" data-bs-ride="carousel">

<!-- Indicators/dots -->
<!-- <div class="carousel-indicators">
    <template v-for="(image, index) in images" :key="image">
        <button  type="button" data-bs-target="#artworkslides" :data-bs-slide-to="index" :class="{active: index == 0}"></button>
    </template>
 
</div> -->

<!-- The slideshow/carousel -->
<div class="carousel-inner" >
    <template v-for="(image, index) in images" :key="image">
  <div class="carousel-item imageSlider text-center " :class="{active: index == 0}">
    <div class="mb-1 imageName">{{ image.name }}</div>
    <div class="text-center"><picture>
  <source media="(min-width:650px)" :srcset="getImage(image.imageLarge)">
  <img :src="getImage(image.imageMobile)" :alt="image.name" class="img-fluid carousel-img">
</picture></div>
    
  </div>
</template>
</div>

<!-- Left and right controls/icons -->
<button class="carousel-control-prev" type="button" data-bs-target="#artworkslides" data-bs-slide="prev">
    <i class="bi bi-caret-left-fill fs-5 text-secondary"></i>
</button>
<button class="carousel-control-next" type="button" data-bs-target="#artworkslides" data-bs-slide="next">
    <i class="bi bi-caret-right-fill fs-5 text-secondary"></i>
</button>
</div>
        </div>
    </div>
 

   
</template>

<script>
import artwork from '@/services/artwork.json'

export default {
    data() {
        return {
            images: artwork.Artwork
        }
    },

    methods: {
        getImage(image) {
            return require(`@/assets/images/JPEG/${image}`)
        }
    },
}
</script>

<style>

</style>