<template>
<div class="container container-padded">
  <p class="contactText lead text-end">Send <a href="mailto:me@monome.nl">e-mail</a> for info and prices</p>
</div>
    
<div class=""><CarouselComp /></div>
  
    
</template>

<script>
import CarouselComp from '@/components/CarouselComp.vue'
export default {
  components: {
    CarouselComp,
  }

}

</script>

<style>


</style>